<template>
  <div class="app-bg">
    <simple-header></simple-header>

    <slot/>
  </div>
</template>

<script>
import SimpleHeader from '../components/Header.vue'

export default {
  mounted () {
    let body = document.body

    body.classList.remove('mainpage')
  },
  components: {
    SimpleHeader
  }
}
</script>
