<template>
  <div id="header-bar">
    <div class="header xs-relative xs-fill-white xs-shadow-down-1 xs-z-100 xs-h-40 md-h-60 lg-static lg-width-auto">
      <a href="javascript:void(0)" v-if="$route.name != 'tracker-order'" @click="back"  class="back-button text-black cursor-pointer decoration-none">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>{{$t('back')}}</span>
      </a>
      <a href="javascript:void(0)" v-else @click="$router.push({name: 'home'})"  class="back-button text-black cursor-pointer decoration-none">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>{{$t('back')}}</span>
      </a>
      <!--<div class="lg-container xs-flex-center md-justify-start md-items-start xs-relative xs-full-height ">
        <div class="xs-relative xs-z-500 xs-mx-auto">
          <router-link to="/"
                       class="header-logo xs-block md-pt-15 md-py-15 md-pl-30 xs-fill-white lg-fill-none circle p-10">
            <span class="logo"></span>
          </router-link>
        </div>
      </div>-->
      <router-link to="/" class="header-logo">
        <span class="logo"></span>
      </router-link>
    </div>
    <h2 class="cond caps xs-text-center xs-py-20 xs-relative xs-my-0 mr15 ml15">
      <span>
        <img src="https://static.phdvasia.com/mx/icons/inactive.png" class="xs-mr-5" v-if="$route.meta.icon">
        <!--<span class="font-20px nanh" >
          <span class="font-20px"  >

          </span>
        </span>-->
            <span v-if="$route.meta.key_title != 'postal_code' "
                  v-html="titleCustom || title"></span>
            <span v-else  class="group-postal-code">
             <img src="../assets/images/icons/zipcode_black.png"/>
              <span class="pl-5 pr-5">{{convertPostalCode($route.params[$route.meta.key_params])}}</span>
            </span>
             <span v-if="$route.name != 'confirmation' && $route.meta.key_title != 'postal_code' && $route.meta.key_params"
                   v-html="$route.params[$route.meta.key_params]">
             </span>
            <span class="sub-title t14"
                  v-if="$route.meta.sub_title"
                  v-html="$t($route.meta.sub_title)">
            </span>
      </span>
      <!--<a class="xs-flex xs-items-center xs-text-gray-medium xs-absolute xs-l-15 xs-t-0 xs-b-0 xs-opacity-50" href="javascript:void(0)" @click="$router.go(-1)" >-->
        <!--<i class="xs-ml-5 icon-caret-left"></i>-->
      <!--</a>-->
    </h2>
  </div>
</template>
<script>
export default {
  props: ['back_link'],
  updated () {
    this.title = this.$t(this.$route.meta.key_title)
  },
  created () {
    this.title = this.$t(this.$route.meta.key_title)
  },
  mounted () {
    var vm = this
    this.title = this.$t(this.$route.meta.key_title)
    this.$root.$on('onChangeMetaTitle', (data) => {
      vm.titleCustom = data
    })
  },
  data () {
    return {
      title: '',
      titleCustom: ''
    }
  },
  computed: {
  },
  methods: {
    convertPostalCode (data) {
      if (!data) {
        return ''
      }
      data = data.slice(0, 3) + '-' + data.slice(3)
      return data
    },
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.redirectToPage(this.$const.SLUG_LIST.DEAL)
      }
    }
  }
}

</script>
<style>
  .group-postal-code{
    display: inline-block;
    height: 36px;
    border: 2px solid #626262;
    border-left: none;
    border-radius: 4px;
  }
  .group-postal-code img{
    margin-top: -2px;
  }

  .back-button {
    z-index: 1000;
    position: absolute;
    top: 30%;
    left: 20px;

  }
</style>
